import "./css/App.css";
import Quiz from "./components/Quiz";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "The Dreamer Quiz";
    if (window.location.href != process.env.REACT_APP_MAIN_URL) {   
      navigate("/");
      window.location.reload(false);
    }
  }, []);

  return (
    <div className="App">
      <Quiz />      
    </div>
  );
}

export default App;
